<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../Table/components/iconEditCheck.vue";
import iconDeleteCheck from "../Table/components/iconDeleteCheck.vue";

export default {
  components: {
    AgGridVue,
    iconEditCheck,
    iconDeleteCheck,
  },
  data() {
    return {
      columnDefs: null,
      //   rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  props: ["page", "perPage", "dataTable"],

  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        width: 110,
      },
      {
        headerName: this.$t("model.model"),
        field: "model_name",
        sortable: true,
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: this.$t("category.edit_name"),
        field: "edit",
        cellRendererFramework: "iconEditCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("category.delete"),
        field: "delete",
        cellRendererFramework: "iconDeleteCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
    ];
  },
  computed: {
    rowData() {
      if (this.dataTable) {
        return this.dataTable.map((model) => {
          return {
            index: 1,
            model_name: model.name,
            edit: model,
            delete: model,
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    onGridReady() {
      //   this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
