<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>

    <!-- navbar -->
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="goBack()" />
      </div>
      <h1 class="header-text flex justify-content-center">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div style="padding: 0.4em">
        <SuccessDialog
          :msg="successMessage"
          v-show="showSuccess"
        ></SuccessDialog>
        <div class="tab-content" id="myTabContent">
          <div class="pt-4 pb-2 gradient-text">
            <h2 class="head-table-text">{{ subCategoryName }}</h2>
          </div>
        </div>
        <div class="mb-4">
          <b-row class="w-100 m-0">
            <b-col
              class="mb-2 mb-sm-0 d-flex justify-content-md-center"
              cols="12"
              md="2"
            >
              <button
                class="d-flex filter text-light p-2 m-0"
                style="border: none"
                type="button"
                @click="openModalAddModel"
              >
                + {{ $t("model.add_model") }}
              </button>
            </b-col>
            <b-col class="d-flex" cols="10" md="9">
              <span
                class="filter-box p-2 d-flex mr-2"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px;"
              >
                <img src="../../assets/search-white.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                    border-right-style: none;
                    border-radius: 0 10px 10px 0 !important;
                  "
                :placeholder="$t('home.search')"
                v-debounce:700ms="searchByText"
                v-model="searchText"
              />
            </b-col>
          </b-row>
        </div>

        <div class="main">
          <!-- Ag Grid User's Table -->
          <AssetModelTable
            ref="userTable"
            :dataTable="modelList"
            :page="currentPage"
            :perPage="perPage"
          />
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalrows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>

          <!-- Modal Delete Asset -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div
                class="modal-content container-fluid pt-4 pb-2"
                style="border-radius: 10px"
              >
                <div class="row">
                  <img
                    class="del-icon mb-3"
                    src="../../assets/delete_bin.svg"
                  />
                  <p class="mb-3">
                    {{ $t("model.delete_model") }}
                  </p>
                </div>
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                  >
                    {{ $t("btn.canc") }}
                  </button>
                  <button
                    type="button"
                    class="confirm-btn col"
                    data-dismiss="modal"
                    @click="confirmDelete()"
                  >
                    {{ $t("btn.conf") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add main assets -->
        <b-modal
          id="add-model-modal"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-form-group>
            <h3 style="text-align: center">
              {{ $t("model.add_model") }}
            </h3></b-form-group
          >
          <b-row>
            <b-col cols="12 " class="d-flex pt-1 pb-3 flex-wrap"> </b-col>
          </b-row>

          <!-- modal add category -->
          <b-row>
            <form @submit.prevent="addModelAsset">
              <b-form-group>
                <b-col cols="12 mt-2">
                  <b-input-group>
                    <b-form-input
                      v-model="mainAsset"
                      :placeholder="$t('model.model')"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-3 flex justify-content-center text-center"
                >
                  <div class="row">
                    <button
                      type="button"
                      class="btn cancle col"
                      data-dismiss="modal"
                      @click="$bvModal.hide('add-model-modal')"
                    >
                      {{ $t("btn.canc") }}
                    </button>
                    <button
                      type="submit"
                      class="col"
                      data-dismiss="modal"
                      :disabled="mainAsset.length < 1"
                      :class="[
                        mainAsset.length < 1 ? 'btn-disabled' : 'confirm',
                      ]"
                    >
                      {{ $t("btn.save") }}
                    </button>
                  </div>
                </b-col>
              </b-form-group>
            </form>
          </b-row>
        </b-modal>
      </div>

      <!-- Modal Edit User -->
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-2 pb-4"
            style="border-radius: 10px"
          >
            <div class="mt-3">
              <b-form-group>
                <h3 style="text-align: center">
                  {{ $t("model.edit_model") }}
                </h3></b-form-group
              >

              <!-- modal edit category -->
              <b-row>
                <b-col cols="12 mt-2">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input
                        v-model="newModelName"
                        :placeholder="$t('model.model')"
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2 flex justify-content-center text-center"
                >
                  <div class="row">
                    <button
                      type="button"
                      class="btn cancle col"
                      data-dismiss="modal"
                      @click="$bvModal.hide('add-model-modal')"
                    >
                      {{ $t("btn.canc") }}
                    </button>
                    <button
                      type="button"
                      class="col"
                      data-dismiss="modal"
                      :disabled="newModelName.length < 1"
                      :class="[
                        newModelName.length < 1 ? 'btn-disabled' : 'confirm',
                      ]"
                      @click="updateModelAsset()"
                    >
                      {{ $t("btn.conf") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import AssetModelTable from "../../components/CustomTable/AssetModelTable.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import Swal from "sweetalert2";
import { authHeader } from "../../store/actions";

export default {
  data() {
    return {
      isLoading: false,
      showSuccess: false,
      successMessage: "",
      id_company: null,
      searchText: "",
      onClick: true,
      editShow: false,
      deleteShow: false,
      filterShow: false,
      searchClick: false,

      name: "editUserModal",
      mainAsset: "",
      newModelName: "",
      isSuccess: false,
      modelList: [],
      createMainAsset: null,
      deleteMainAsset: null,
      delModelID: null,
      update_modelcategory: null,
      currentPage: 1,
      perPage: 100,
      totalrows: 1,
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
    AssetModelTable,
    SuccessDialog,
  },

  computed: {
    ...mapGetters({
      subcategory: "subcategory",
    }),
    subCategoryName() {
      return this.subcategory.find(
        (main) => main.id_sub_category == this.$route.params.id_subcategory
      )?.name;
    },
  },

  watch: {
    currentPage() {
      this.getAssetModel();
    },
  },

  methods: {
    deleteUser() {
      this.$refs.userTable.getSelectedRowData();
    },
    async openModalAddModel() {
      await this.$bvModal.show("add-model-modal");
    },
    clickBox(id) {
      let box = document.getElementById(`box${id}`);
      box.click();
    },

    goBack() {
      this.$router.back();
    },

    showDelete(model) {
      this.deleteShow = true;
      this.delModelID = model.id_sub_category2;
    },

    cancleDelete() {
      this.deleteShow = false;
    },

    async confirmUser() {
      // Add console;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.$store.dispatch("confirmUser", {
        id_user: this.data.id,
        company_id: id_company,
      });
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    async setModelEdit(model) {
      console.log(model);
      this.update_modelcategory = model.id_sub_category2;
      this.newModelName = model.name;
    },

    backToDetail() {
      this.editShow = false;
    },

    async addModelAsset() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.mainAsset = this.mainAsset.trim();
      if (this.mainAsset) {
        let data = {
          name: this.mainAsset,
          id_company: id_company,
        };
        try {
          const res = await axios.post(
            `${baseUrl()}category/create/subcategory2`,
            {
              name: this.mainAsset,
              id_company: this.id_company,
              id_sub_category: this.$route.params.id_subcategory,
            },
            authHeader()
          );
          this.successMessage = this.$t("dialog.add_success");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          // console.log("error addsub",e.response.data.message);
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_model_dup"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.mainAsset = "";
        this.getAssetModel();
        this.$bvModal.hide("add-model-modal");
      }
      this.isLoading = false;
    },

    async confirmDelete() {
      try {
        await axios.delete(
          `${baseUrl()}category/delete/subcategory2/${this.delModelID}`,
          authHeader()
        );
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
        this.getAssetModel();
      } catch (err) {
        console.log(err);
      }
    },

    async updateModelAsset() {
      this.isLoading = true;
      this.newModelName = this.newModelName.trim();
      if (this.newModelName) {
        try {
          const res = await axios.put(
            `${baseUrl()}category/update/subcategory2`,
            {
              id_sub_category: this.$route.params.id_subcategory,
              id_sub_category2: this.update_modelcategory,
              name: this.newModelName,
              id_company: this.id_company,
            },
            authHeader()
          );
          this.successMessage = this.$t("dialog.save_edit");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          // console.log("error addsub",e.response.data.message);
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_model_dup"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.getAssetModel();
      }
      this.isLoading = false;
    },

    searchByText() {
      this.getAssetModel();
    },
    async getAssetModel() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl()}category/get/subcategory2`, {
          params: {
            id_company: this.id_company,
            id_sub_category: this.$route.params.id_subcategory,
            page: this.currentPage,
            limit: this.perPage,
            text: this.searchText,
          },
          ...authHeader(),
        });
        this.modelList = res.data.data;
        this.totalRows = res.data.count;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.getAssetModel();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}
.tag-email {
  display: flex;
  // height: 30px;  font-size: 14px;

  color: #000000;
  background: #c7c7c7;
  width: fit-content;
  border-radius: 15px;

  padding: 0.25em 1.25em 0.25em 1em;
  margin: 0.25em 0.4em;
}
.delete-tag {
  width: 20px !important;
  height: 20px !important;
  font-size: 13px !important;
  border-radius: 50%;
  background: #7e7e7e;
  margin: 0px !important;
  border: none;
  color: #c7c7c7 !important;
  cursor: pointer;
}

@media only screen and (min-width: 1440px) {
  .main {
    // overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}
.btn-disabled {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #cecece 0%, #7c7c7c 100%) 0%
    0% no-repeat padding-box;
  cursor: not-allowed !important;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.confirm-btn {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #fe0000 0%, #810101 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content !important;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
// .modal-content {
//   // padding: 1rem;
//   border-radius: 35px;
//   height: auto;

//   & label {
//     color: #4b4b4b;
//     padding: 0.4rem;
//   }
// }

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
